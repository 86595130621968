import React from 'react';

import HeaderDark from '../../../common/header/HeaderDark';
import FooterOne from '../../../common/footer/FooterOne';
import team from '../../../../src/app/assets/images/mteam/team.jpg'

import team1 from '../../../../src/app/assets/images/mteam/1.jpg'
import team2 from '../../../../src/app/assets/images/mteam/2.jpg'
import team3 from '../../../../src/app/assets/images/mteam/3.jpg'
import team4 from '../../../../src/app/assets/images/mteam/4.jpg'
import team5 from '../../../../src/app/assets/images/mteam/5.jpg'
import team6 from '../../../../src/app/assets/images/mteam/6.jpg'
import team7 from '../../../../src/app/assets/images/mteam/7.jpg'
import team10 from '../../../../src/app/assets/images/mteam/10.jpg'
import team11 from '../../../../src/app/assets/images/mteam/11.jpg'
import team12 from '../../../../src/app/assets/images/mteam/12.jpg'
import team13 from '../../../../src/app/assets/images/mteam/13.jpg'
import team15 from '../../../../src/app/assets/images/mteam/15.jpg'
import team16 from '../../../../src/app/assets/images/mteam/16.jpg'

import Meta from "../../Meta/Management-team";

const Mteam = () => {
    
return (
<div className="mtam-page">
    <HeaderDark />
    <Meta/>
    <section
        class="d-flex align-items-center justify-content-center position-relative banner-sec inner text-center text-lg-start pt-0 position-relative sharp-object">
        <div class="team-overlay"></div>
        <div class="container custom-cn-width position-relative">
            <div class="row g-lg-5 g-4 align-items-center justify-content-start">
                <div class="col-lg-5 pe-lg-5">
                    <h2>Empowering people and business</h2>
                    <p class="mt-3 font-size-19">Our team has remained committed to providing consistent and quality results to our clients for over 10 years.</p>
                </div>
                <div class="col-lg-7">
                    <img src={team} alt="team" class="img-fluid mb-1 mt-4" />
                </div>
            </div>
        </div>
    </section>

    <section class="pt-xl-0 text-center section-padding">
        <div class="container custom-cn-width">
            <div class="row g-lg-5 g-4 justify-content-center">
                <div class="col-lg-12 mb-4 mb-lg-5">
                    <h1 class="fw-light">Our team members</h1>
                </div>
            </div>
            <div class="row g-xl-5 g-4">
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team1} width={307} height={520} alt="team" class="img-fluid mb-1" />
                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Arun Kumar</p>
                        <p class="h6 fw-light font-size-17">Founder, President and Chief Executive Officer</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team2} alt="team" class="img-fluid mb-1" />
                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Shankar Selvaraj</p>
                        <p class="h6 fw-light font-size-17">Executive Director</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team3} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Barath Kumar</p>
                        <p class="h6 fw-light font-size-17">Director &amp; Vice President</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team4} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Anitha</p>
                        <p class="h6 fw-light font-size-17">Chief Financial Officer</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team5} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Prakash Natraj</p>
                        <p class="h6 fw-light font-size-17">Vice President – BPO Division</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team6} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Saravanan</p>
                        <p class="h6 fw-light font-size-17">General Manager - Administration</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team7} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Rick Litzky</p>
                        <p class="h6 fw-light font-size-17">VP - Business Development</p>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team10} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Xiomara Guerra</p>
                        <p class="h6 fw-light font-size-17">Growth Specialist</p>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team11} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Kelley Reece</p>
                        <p class="h6 fw-light font-size-17">Medical Legal Administrative Assistant</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team12} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Scott Jensen</p>
                        <p class="h6 fw-light font-size-17">Client Support Manager</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team13} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Praveenkumar</p>
                        <p class="h6 fw-light font-size-17">Project Manager</p>
                    </div>
                </div>
                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team15} alt="team" class="img-fluid mb-1" />

                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Senthilkumar</p>
                        <p class="h6 fw-light font-size-17">Team Leader - Web Design</p>
                    </div>
                </div>

                <div class="col-xxl-3 col-xl-4 col-md-6 team-block">
                    <div class="team-image">
                        <img src={team16} alt="team" class="img-fluid mb-1" />
                    </div>
                    <div class="team-desc">
                        <p class="h4 mb-2 font-size-26 fw-400">Suresh Kumar</p>
                        <p class="h6 fw-light font-size-17">Team Leader</p>
                    </div>
                </div>
            </div>
            <div class="row g-xl-5 g-4 line-box-4 mt-0">
                <div class="col-lg-3">
                    <h1 class="fw-semibold">50+ </h1>
                    <h5 class="fw-400 mt-1">Software Engineers</h5>
                </div>
                <div class="col-lg-3">
                    <h1 class="fw-semibold">300+ </h1>
                    <h5 class="fw-400 mt-1">Data Scientists</h5>
                </div>
                <div class="col-lg-3">
                    <h1 class="fw-semibold">10+ </h1>
                    <h5 class="fw-400 mt-1">Nearshore Call Center Executive</h5>
                </div>
                <div class="col-lg-3">
                    <h1 class="fw-semibold">7+ </h1>
                    <h5 class="fw-400 mt-1">Sales Executive</h5>
                </div>
            </div>
        </div>
    </section>
    <section class="pt-0 section-padding">
		<div class="container custom-cn-width">
			<div class="cta-block cta-blue b-r-6">
				<div class="row g-lg-5 g-4 align-items-center justify-content-center">
					<div class="col-lg-9">				
						<h2 class="fw-light text-white">Tell us your marketing issue or growth objective! We will help you solve it and grow your business.</h2>
					</div>
					<div class="col-lg-3 text-lg-end">
						<a href="javascript:;" class="btn cust-btn-primary" data-bs-toggle="modal" data-bs-target="#meeting">Let's Talk</a>
					</div>
				</div>
			</div>
		</div>
	</section>
    <FooterOne />
</div>
)
}

export default Mteam;