import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/edexa/1.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/edexa/2.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/edexa/3.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/edexa/4.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/js.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/python.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/dj.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/paperboy-1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/off-road-1.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/finax1.png";

const Edexa = () => {
  useEffect(() => {
    document.title = "Edexa | CAP Digisoft ";
  }, []);
  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Edexa</h2>
          <p class="text-white text-center pb-4 pt-3">
            Leveraging AI technology to deliver solutions directly from books
            and images.
          </p>
          <div class="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-xl-3 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Edexa?</p>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-md-8 col-sm-12">
              <p>
                Edexa is an AI-powered tool designed to provide precise answers
                directly from books. By selecting a book and asking a question,
                the AI fetches accurate solutions based on that specific
                content.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                Students often need quick, reliable answers from textbooks or
                images, and traditional methods of searching can be
                time-consuming and inefficient.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                Edexa uses AI to interpret and extract answers from books or
                uploaded images, providing detailed responses. Users can even
                take a photo, upload it, and receive AI-generated solutions
                based on the content.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li>Faster access to book-based solutions</li>
                <li>AI-driven responses to image content</li>
                <li>Enhanced learning through automated answers</li>
                <li>Streamlined study process</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4 d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                Edexa transforms how students interact with educational content,
                making the process faster and more efficient by using AI to
                deliver accurate solutions from books and images.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/paper_boy">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Paper Boy</h4>
                  </div>
                  <p class="pt-2">
                    Sharing knowledge while earning through MLM
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/off_road_treasure_quest">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Off Road Treasure Quest</h4>
                  </div>
                  <p class="pt-2">
                    Adventure and discovery through off-road challenges
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/finax">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Finax</h4>
                  </div>
                  <p class="pt-2">
                    AI platform delivers insights for confident decisions
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Edexa;
