import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/workflow-ms/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/workflow-ms/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/workflow-ms/icon2.png";

import PDF from "../../../app/assets/casestudypdf/workflow-management-system.pdf";

const WorkFlow = () => {
  useEffect(() => {
    document.title = "Workflow management system | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-logistics-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div class="container custom-cn-width position-relative">
          <div class="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div class="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold">Workflow Management System</h1>
            </div>
            <div class="col-lg-3 text-lg-end">
              <a href={PDF} class="default-btn orange-btn" target="_blank">
                <i class="fa fa-file-pdf me-1"></i>
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center g-lg-5 g-4 align-items-center">
            <div class="col-lg-7 col-md-9">
              <h1 class="fw-light mb-lg-4 mb-3">Scenario</h1>
              <p class="mt-3">
                A client that runs a firm approached us for an order and
                tracking tool as they did not have the necessary expertise to
                select an efficient vendor. They lacked proper tracking options
                and the existing systems and processes were manual and
                time-consuming.
              </p>
            </div>
            <div class="col-lg-5 col-md-9">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 text-center text-lg-start section-padding pt-0">
        <div class="container custom-cn-width">
          <div class="row g-xl-5 g-4">
            <div class="col-lg-7 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
            </div>
          </div>
          <div class="row g-xl-5 g-4">
            <div class="col-md-6 col-lg-6">
              <div class="features-item h-100 justify-content-start">
                <div class="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Approach</h4>
                <p class="mt-3">
                  Our approach was to develop a cloud-based Workflow System
                  solution that can act as an order and tracking tool - a
                  workflow management system along with inbuilt CMS. We
                  developed a fully customized and automated system where the
                  process of Document Management could be done entirely online.
                  Revilix, previously known as “Vendor Tree”, utilizes a HIPAA
                  compliant, cloud-based Workflow System solution which is an
                  application that acts as an order and tracking tool. The
                  system eliminates industry defects related to ordering,
                  tracking, quality, process, delivery, and pricing.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="features-item h-100 justify-content-start">
                <div class="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Prospect Database Governance"
                  />
                </div>
                <h4>Strategy</h4>
                <p class="mt-3">
                  Firms registered with the Workflow Management System can
                  create a job and submit job-related documents. The selection
                  of services required for jobs is handled via a user-friendly
                  template in which the services are selected by clicking a
                  button or checkboxes. Selected services are auto-compiled into
                  a single job order and sent to Workflow management System. The
                  system then selects a suitable vendor and submits the job to
                  them. The vendor completes the job and returns it to Workflow
                  management System.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
          <div class="row g-lg-0 g-4 justify-content-center">
            <div class="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light text-white">Results</h1>
                <p class="text-white"> The benefits to the client included:</p>
                <ul class="mb-3 text-white text-start ps-lg-4 ps-2 list-styled">
                  <li className="text-white">
                    The process of job handling was user-friendly
                  </li>
                  <li className="text-white">
                    They were able to close the job cycle quickly
                  </li>
                  <li className="text-white">
                    Various services were combined into a single job
                  </li>
                  <li className="text-white">
                    The process documents were maintained in a HIPAA compliant
                    Cloud
                  </li>
                  <li className="text-white">
                    Fast and intuitive process completion
                  </li>
                  <li className="text-white">
                    There was no requirement for a repository of documents from
                    the firm’s end
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light  mb-3">Conclusion</h1>

                <p class="mt-3 ">
                  In the end, the client saw the real benefits of the Workflow
                  Management System that performs a routine check of documents
                  and approves them to be forwarded to the firm. The Invoices
                  related to the job are also maintained within the system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div class="container custom-cn-width">
          <div class="row g-5 align-items-center justify-content-center text-center">
            <div class="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div class="container custom-cn-width">
          <div class="row g-lg-5 g-4 text-center">
            <div class="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div class="">
            <div class="row g-4 g-xl-5 text-center text-xl-start">
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Panera Bread</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/panera-bread">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">E-Sort Desk</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/e-sort-desk-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Frontera Search Partners
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/frontera">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default WorkFlow;
