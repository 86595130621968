import "./App.css";
import React from 'react';

import { Route, Routes, BrowserRouter } from "react-router-dom";
import {  HelmetProvider } from 'react-helmet-async';

import HomeOne from "./components/pages/Home01";

// main menu imports
import Products from "./components/pages/products";

// about menu imports
import Ourcompany from "./components/pages/Aboutus/ourcompany";
import Mteam from "./components/pages/Aboutus/mteam";
import Careers from "./components/pages/Aboutus/careers";
import Pressrelease from "./components/pages/Aboutus/pressrelease";
import PressDetailOne from "./components/pages/Aboutus/cds-opens-new-call-center-office-in-el-salvador";
import PressDetailTwo from "./components/pages/Aboutus/joiint-inc-introduces-a-new-application";

// insights menu imports
import Blog from "./components/pages/Insights/blogs/blog";
import Freedownload from "./components/pages/Insights/freedownload-list";
import DownloadBook from "./components/pages/freedownloads";
import Casestudy from "./components/pages/Insights/casestudy";

// Blog innerpages import
import OCSWAPS from "./components/pages/Insights/blogs/optimizing-customer-service-with-ai-powered-solutions";
import OEHTRTW from "./components/pages/Insights/blogs/the-office-evolved-how-technology-is-reshaping-the-workplace";
import BAFWSI from "./components/pages/Insights/blogs/building-a-fortress-enhancing-web-security-in-2024";
import DCETNPD from
"./components/pages/Insights/blogs/the-digital-craftsmen-essential-tech-for-next-gen-product-development";
import SCTCSCW from
"./components/pages/Insights/blogs/scaling-success-the-top-5-customer-success-strategies-winning-todays-tech-race";
import CSTEEBT from "./components/pages/Insights/blogs/customer-success-the-engine-of-enduring-business-triumph";
import TIUUTDL from "./components/pages/Insights/blogs/the-importance-of-ui-ux-design-in-todays-digital-landscape";
import IUHIITC from
"./components/pages/Insights/blogs/innovation-unleashed-how-idea-booster-initiatives-are-transforming-corporate-culture";
import AMLTPOE from "./components/pages/Insights/blogs/accelerating-market-launch-the-power-of-expertise";
import CMLHITS from
"./components/pages/Insights/blogs/crafting-market-leaders-how-idea-to-product-services-forge-the-future";
import NTSDBTD from "./components/pages/Insights/blogs/navigating-the-software-development-boom-a-techcrunch-deep-dive";
import ESHCSIRTW from
"./components/pages/Insights/blogs/empathy-as-strategy-how-customer-success-is-redefining-techs-winners";
import DSVMFGC from
"./components/pages/Insights/blogs/decoding-success-vital-metrics-for-gauging-customer-impact-and-business-growth";
import APNFSCS from
"./components/pages/Insights/blogs/ai-and-personalization-the-new-frontier-in-saas-customer-success";
import MTPDLLS from
"./components/pages/Insights/blogs/mastering-the-product-development-lifecycle-leveraging-stats-for-success";
import TTIOSRP from
"./components/pages/Insights/blogs/the-tech-infused-outsourcing-strategy-revolutionizing-product-development";
import HCIRTRO from
"./components/pages/Insights/blogs/how-csaas-is-rewriting-the-rulebook-on-customer-experience-and-revenue-growth";
import ACTAIRH from
"./components/pages/Insights/blogs/ais-cash-tsunami-and-the-innovation-race-how-techs-betting-big-and-why-millennials-are-all-in";
import TMOKPOBI from "./components/pages/Insights/blogs/the-impact-of-knowledge-processing-on-business-intelligence";
import MSFTAEO from "./components/pages/Insights/blogs/marketing-strategies-for-the-age-of-everything-online";

// Case study detail pages imports
import Fura from "./components/pages/Insights/fura";
import Iledger from "./components/pages/Insights/iledger";
import Genie from "./components/pages/Insights/genie";
import Practica from "./components/pages/Insights/practica";
import MergersAndAcquisitionFirm from "./components/pages/Insights/mergers-and-acquisition-firm";
import Logistics from "./components/pages/Insights/logistics-company";
import Attesta from "./components/pages/Insights/attesta";
import HireHarmony from "./components/pages/Insights/hireharmony";
import WorkflowManagementSystem from "./components/pages/Insights/workflow-management-system";
import MedicoViewDeskSystem from "./components/pages/Insights/medico-view-desk-system";
import NarrativeMedicalRecordSummaries from "./components/pages/Insights/narrative-medical-record-summaries";
import Frontera from "./components/pages/Insights/frontera";
import JP from "./components/pages/Insights/j&p";
import Tracky from "./components/pages/Insights/tracky";
import Skoolers from "./components/pages/Insights/skoolers";
import PaneraBread from "./components/pages/Insights/panera-bread";
import LitiCodeSystem from "./components/pages/Insights/liti-code-system";
import AiDriven from "./components/pages/Insights/ai-driven-optimization-of-prescription-fulfillment";
import Intellicue from "./components/pages/Insights/intellicue";
import MedicoRiskChartAppSystem from "./components/pages/Insights/medico-risk-chart-app-system";
import MedicoAi from "./components/pages/Insights/medico-ai-chrono-sort-app-attesta-system";
import Esort from "./components/pages/Insights/e-sort-desk-system";
import CluedInCloset from "./components/pages/Insights/clued-in-closet";
import Bussopi from "./components/pages/Insights/bussopi";
import Reeler from "./components/pages/Insights/reeler";
import HelpFindingMyAgent from "./components/pages/Insights/helpfindingmyagent";
import Jugl from "./components/pages/Insights/jugl";
import Aiya from "./components/pages/Insights/aiya";
import Steppa from "./components/pages/Insights/steppa";
import SmatchSwatcher from "./components/pages/Insights/smart-swatcher";
import RCM from "./components/pages/Insights/rcm";
import Carfidante from "./components/pages/Insights/carfidante";
import Predixa from "./components/pages/Insights/predixa";
import Finax from "./components/pages/Insights/finax";
import Verific from "./components/pages/Insights/verific";
import SunBelt from "./components/pages/Insights/sunbelt";
import MeetingMinder from "./components/pages/Insights/meeting-minder";
import Edexa from "./components/pages/Insights/edexa";
import PaperBoy from "./components/pages/Insights/paper_boy";
import OffRoadTreasureQuest from "./components/pages/Insights/off_road_treasure_quest";
import Procurement from "./components/pages/Insights/procurexpert";
import MyEmisha from "./components/pages/Insights/my-emisha";
import Linc from "./components/pages/Insights/linc";
import MyGooroo from "./components/pages/Insights/mygooroo";
import BukBuddy from "./components/pages/Insights/buk-buddy";

// contactus menu import
import ContactUs from "./components/pages/Contactus";

// solutions menu import
import Genai from "./components/pages/Solutions/generative-ai";
import ClientSuccess from "./components/pages/Solutions/client-success";
import StaffSolutions from "./components/pages/Solutions/staffing-support-service";
import WebDesign from "./components/pages/Solutions/comprehensive-website-design";
import KPO from "./components/pages/Solutions/knowledge-process-outsourcing";
import B2B from "./components/pages/Solutions/b2b-lead-generation-expert";
import ItSupport from "./components/pages/Solutions/it_support";

// common import

import TermsAndConditions from "./components/terms-of-use";
import PrivacyPolicy from "./components/privacy-policy";

//form import
import BookMeeting from "./components/pages/Form/bookmeeting";

//thank you
import ThankYouPage from "./components/pages/Thank/ThankYou";
import ThankDownload from "./components/pages/Thank/ThankYouDownload";


//Other Pages
import Error404 from "./components/pages/other-pages/404"; 
import Slides from "./components/pages/other-pages/slides"; 


import ScrollToTop from './ScrollToTop';
import Ganalytics from './Ganalytics';
import Meta from './Meta';



function App() {
  const currRoute = window.location.pathname.split('/').pop();
return (
  <HelmetProvider>
<BrowserRouter basename="/">
<Meta currRoute={currRoute} />
<ScrollToTop />
<Ganalytics />

  <Routes>
  

    {/* Cap Digisoft Main Pages */}
    <Route exact path="/" element={<HomeOne />} />


    {/* Solutions Pages Links */}
    <Route path="/generative-ai" element={<Genai />} />
    <Route path="/client-success" element={<ClientSuccess />} />
    <Route path="/it_support" element={<ItSupport />} />
    <Route path="/staffing-support-service" element={<StaffSolutions />} />
    <Route path="/comprehensive-website-design" element={<WebDesign />} />
    <Route path="/knowledge-process-outsourcing" element={<KPO />} />
    <Route path="/b2b-lead-generation-expert" element={<B2B />} />


    {/* Products Page Link */}
    <Route path="/products" element={<Products />} />


    {/* About Us Page Link */}
    <Route path="/ourcompany" element={<Ourcompany />} />
    <Route path="/management-team" element={<Mteam />} />
    <Route path="/careers" element={<Careers />} />
    <Route path="/pressrelease" element={<Pressrelease />} />

      {/* Press Release Page Links */}
      <Route path="/cds-opens-new-call-center-office-in-el-salvador" element={<PressDetailOne />}/>
      <Route path="/joiint-inc-introduces-a-new-application" element={<PressDetailTwo />}/>


    {/* Insights Page Link */}
    <Route path="/blog" element={<Blog />} />
      {/* Blog Pages Links */}
      <Route path="/optimizing-customer-service-with-ai-powered-solutions" element={<OCSWAPS />} />
      <Route path="/the-office-evolved-how-technology-is-reshaping-the-workplace" element={<OEHTRTW />} />
      <Route path="/building-a-fortress-enhancing-web-security-in-2024" element={<BAFWSI />} />
      <Route path="/the-digital-craftsmen-essential-tech-for-next-gen-product-development" element={<DCETNPD />}></Route>
      <Route path="/scaling-success-the-top-5-customer-success-strategies-winning-todays-tech-race" element={<SCTCSCW />}></Route>
      <Route path="/customer-success-the-engine-of-enduring-business-triumph" element={<CSTEEBT />}></Route>
      <Route path="/the-importance-of-ui-ux-design-in-todays-digital-landscape" element={<TIUUTDL />}></Route>
      <Route path="/innovation-unleashed-how-idea-booster-initiatives-are-transforming-corporate-culture" element={<IUHIITC />}></Route>
      <Route path="/accelerating-market-launch-the-power-of-expertise" element={<AMLTPOE />}></Route>
      <Route path="/crafting-market-leaders-how-idea-to-product-services-forge-the-future" element={<CMLHITS />}></Route>
      <Route path="/navigating-the-software-development-boom-a-techcrunch-deep-dive" element={<NTSDBTD />}></Route>
      <Route path="/empathy-as-strategy-how-customer-success-is-redefining-techs-winners" element={<ESHCSIRTW />}></Route>
      <Route path="/decoding-success-vital-metrics-for-gauging-customer-impact-and-business-growth" element={<DSVMFGC />}></Route>
      <Route path="/ai-and-personalization-the-new-frontier-in-saas-customer-success" element={<APNFSCS />}></Route>
      <Route path="/mastering-the-product-development-lifecycle-leveraging-stats-for-success" element={<MTPDLLS />}></Route>
      <Route path="/the-tech-infused-outsourcing-strategy-revolutionizing-product-development" element={<TTIOSRP />}></Route>
      <Route path="/how-csaas-is-rewriting-the-rulebook-on-customer-experience-and-revenue-growth" element={<HCIRTRO />}></Route>
      <Route path="/ais-cash-tsunami-and-the-innovation-race-how-techs-betting-big-and-why-millennials-are-all-in" element={<ACTAIRH />}></Route>
      <Route path="/the-impact-of-knowledge-processing-on-business-intelligence" element={<TMOKPOBI />}></Route>
      <Route path="/marketing-strategies-for-the-age-of-everything-online" element={<MSFTAEO/>}></Route>

    <Route path="/casestudy" element={<Casestudy />} />
      {/* Case Study Pages Links */}
      <Route path="/fura" element={<Fura />} />
      <Route path="/iledgers" element={<Iledger />} />
      <Route path="/genie" element={<Genie />} />
      <Route path="/practica" element={<Practica />} />
      <Route path="/mergers-and-acquisition-firm" element={<MergersAndAcquisitionFirm />}/>
      <Route path="/logistics-company" element={<Logistics />} />
      <Route path="/attesta_ai" element={<Attesta />} />
      <Route path="/hireharmony" element={<HireHarmony />} />
      <Route path="/workflow-management-system" element={<WorkflowManagementSystem />}/>
      <Route path="/medico-view-desk-system" element={<MedicoViewDeskSystem />}/>
      <Route path="/narrative-medical-record-summaries" element={<NarrativeMedicalRecordSummaries />}/>
      <Route path="/frontera" element={<Frontera />} />
      <Route path="/j&p_services+" element={<JP />} />
      <Route path="/tracky" element={<Tracky />} />
      <Route path="/skoolers" element={<Skoolers />} />
      <Route path="/panera-bread" element={<PaneraBread />} />
      <Route path="/liti-code-system" element={<LitiCodeSystem />} />
      <Route path="/ai-driven-optimization-of-prescription-fulfillment" element={<AiDriven />}/>
      <Route path="/intellicue" element={<Intellicue />} />
      <Route path="/medico-risk-chart-app-system" element={<MedicoRiskChartAppSystem />}/>
      <Route path="/medico-ai-chrono-sort-app-attesta-system" element={<MedicoAi />}/>
      <Route path="/e-sort-desk-system" element={<Esort />} />
      <Route path="/clued-in-closet" element={<CluedInCloset />} />
      <Route path="/bussopi" element={<Bussopi />} />
      <Route path="/reeler" element={<Reeler />} />
      <Route path="/helpfindingmyagent" element={<HelpFindingMyAgent />} />
      <Route path="/jugl" element={<Jugl />} />
      <Route path="/aiya" element={<Aiya />} />
      <Route path="/steppa" element={<Steppa />} />
      <Route path="/smart-swatchers" element={<SmatchSwatcher />} />
      <Route path="/rcm" element={<RCM />} />
      <Route path="/carfidante" element={<Carfidante />} />
      <Route path="/predixa" element={<Predixa />} />
      <Route path="/finax" element={<Finax />} />
      <Route path="/verific" element={<Verific />} />
      <Route path="/sunbelt" element={<SunBelt />} />
      <Route path="/meeting-minder" element={<MeetingMinder />} />
      <Route path="/edexa" element={<Edexa />} />
      <Route path="/paper_boy" element={<PaperBoy />} />
      <Route path="/off_road_treasure_quest" element={<OffRoadTreasureQuest />} />
      <Route path="/procurexpert" element={<Procurement />} />
      <Route path="/myemisha" element={<MyEmisha />} />
      <Route path="/linc" element={<Linc />} />
      <Route path="/mygooroo" element={<MyGooroo />} />
      <Route path="/buk_buddy" element={<BukBuddy />} />

    <Route path="/freedownload" element={<Freedownload />} />
      {/* Download Page Links */}
      <Route path="/downloadbook/:id" element={<DownloadBook />} />
 

    {/* Contact Us Page Link */}
    <Route path="/contactus" element={<ContactUs />} />

    {/* Other Pages Links */}
    <Route path="/slides" element={<Slides />} />

    {/* common route */}
    <Route path="/terms-of-use" element={<TermsAndConditions />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

    {/* form route */}
    <Route path="/form" element={<BookMeeting />} />

    {/* thankyou route */}
    <Route path="/thankyou" element={<ThankYouPage />} />
    <Route path="/thankyoudownload" element={<ThankDownload />} />

    <Route path="*" element={<Error404 />} />
    <Route path="/mobile-software-development"  element={<RedirectToExternal url="https://softwareappdevelopment.capdigisoft.com/" />}  />
    <Route path="/idea-booster"  element={<RedirectToExternal url="https://ideabooster.capdigisoft.com" />}  />
    <Route path="/idea-to-product"  element={<RedirectToExternal url="https://ideatoproduct.capdigisoft.com/" />}   />
    <Route path="/plug-and-play-call-center-service"  element={<RedirectToExternal url="https://callcenter.capdigisoft.com" />}   />
 </Routes>
</BrowserRouter>
</HelmetProvider>
);
}

const RedirectToExternal = ({ url }) => {
  window.location.href = url;
  return null;
};
export default App;