import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/frontera/banner.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/frontera/cutout1.png";

import icon1 from "../../../../src/app/assets/images/casestudy/common/icon5.png";
import icon2 from "../../../../src/app/assets/images/casestudy/common/icon6.png";
import icon3 from "../../../../src/app/assets/images/casestudy/common/icon3.png";
import icon4 from "../../../../src/app/assets/images/casestudy/common/icon4.png";

import PDF from "../../../app/assets/casestudypdf/frontera-case-study.pdf";

const Frontera = () => {
  useEffect(() => {
    document.title = "Frontera | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-frontera-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div class="container custom-cn-width position-relative">
          <div class="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div class="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold">Frontera Search Partners</h1>
            </div>
            <div class="col-lg-3 text-lg-end">
              <a href={PDF} class="default-btn orange-btn" target="_blank">
                <i class="fa fa-file-pdf me-1"></i>
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center g-lg-5 g-4 align-items-center">
            <div class="col-lg-7 col-md-12">
              <h1 class="fw-light mb-lg-4 mb-3">Overview</h1>
              <p class="mt-3">
                Frontera Search Partners, a leading healthcare staffing firm,
                faced challenges in efficiently reaching prospects, setting
                meetings, and converting customers. To overcome these obstacles,
                Frontera sought the expertise of CAP Digisoft, a renowned
                provider of Sales Development Representative (SDR) services.
                This case study outlines how CAP Digisoft's SDR services
                transformed Frontera's outreach process, substantially
                increasing conversion rates.
              </p>
              <p class="mt-3">
                Frontera Search Partners specializes in providing healthcare
                staffing services for hospitals, clinics, and other medical
                facilities. With over 20 years of experience, they focus on
                staffing physicians, advanced practitioners, and allied health
                providers into temporary staffing assignments. Their team of
                search partners collaborates closely with each client and
                candidate to ensure that all parties benefit from their best
                practices.
              </p>
              <p class="mt-3">
                However, Frontera struggled to optimize their sales outreach and
                follow-up processes, which led to missed opportunities and lower
                conversion rates.
              </p>
            </div>
            <div class="col-lg-5 col-md-9">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div class="container">
          <div class="row ">
            <div class="col-lg-7 col-sm-12 order-2 order-lg-1 ">
              <img src={cutout1} class="shadow img-fluid b-r-6" />
            </div>
            <div class="col-lg-5 order-1 order-lg-2 position-relative">
              <div class="m-left mb-5 mb-lg-0">
                <div>
                  <h1 class="fw-light  mb-3">Challenges faced</h1>
                  <p class="">
                    Frontera faced several challenges that hindered its sales
                    performance:
                  </p>
                  <ul>
                    <li>
                      Limited resources for prospecting and outreach efforts
                    </li>
                    <li>
                      Difficulty in identifying and connecting with the right
                      decision-makers
                    </li>
                    <li>Inefficient and inconsistent follow-up processes</li>
                    <li>Low conversion rates</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding ">
        <div class="container">
          <div class="row g-xl-5 g-4 justify-content-center text-center">
            <div class="col-lg-6 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
              <p>
                CAP Digisoft's SDR services were implemented to streamline
                Frontera's outreach process, allowing them to effectively send
                emails, connect with prospects, set meetings, and convert
                customers. The following steps were taken:
              </p>
            </div>
          </div>
          <div class="row g-xl-5 g-4">
            <div class=" col-lg-6">
              <div class="features-item h-100 justify-content-center">
                <div class="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Personalized Email Outreach</h4>
                <p class="mt-3">
                  CAP Digisoft developed a personalized email campaign that
                  captured the attention of prospects and clearly communicated
                  Frontera's value proposition.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="features-item h-100 justify-content-start">
                <div class="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Prospect Database Governance"
                  />
                </div>
                <h4>Persistent Follow-ups and Cold Calling</h4>
                <p class="mt-3">
                  CAP Digisoft's SDRs were diligent in their follow-up efforts,
                  ensuring no potential opportunity was overlooked.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="features-item h-100 justify-content-start">
                <div class="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center  sec-primary-ic rounded-pill">
                  <img
                    src={icon3}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Setting Meetings</h4>
                <p class="mt-3">
                  By engaging with prospects on various channels and using
                  effective communication techniques, CAP Digisoft's SDRs
                  successfully set up meetings with key decision-makers.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="features-item h-100 justify-content-start">
                <div class="icons mb-4 mx-auto mx-lg-0 d-flex align-items-center justify-content-center  sec-primary-ic rounded-pill">
                  <img
                    src={icon4}
                    class="img-fluid"
                    alt="Email Marketing Strategy"
                  />
                </div>
                <h4>Conversion Optimization</h4>
                <p class="mt-3">
                  CAP Digisoft worked closely with Frontera to continuously
                  optimize their sales process, driving better results and
                  higher conversion rates.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
          <div class="row g-lg-0 g-4 justify-content-center">
            <div class="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light text-white">Results</h1>
                <p class="mt-3 mb-3 text-white">
                  After implementing CAP Digisoft's SDR services, Frontera
                  Search Partners experienced significant improvements in their
                  sales process, leading to the following results:
                </p>
                <ul class="text-white list-styled ps-5">
                  <li class="text-white">
                    5127 emails per month sent on their behalf
                  </li>
                  <li class="text-white">
                    More than 25 qualified high-value prospect meetings set per
                    month through cold-calling, social media outreach, email
                    campaigns, and SMS{" "}
                    <span class="text-bold"> - A 250% increase</span>
                  </li>
                  <li class="text-white">
                    Improved efficiency and effectiveness of the sales team,
                    allowing them to focus on closing deals
                  </li>
                  <li class="text-bold text-white">
                    Increased revenue and business growth
                  </li>
                  <ul></ul>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light  mb-3">Conclusion</h1>

                <p class="mt-3 ">
                  CAP Digisoft's SDR services have proven instrumental in
                  helping Frontera Search Partners overcome their sales
                  challenges and significantly improve their conversion rates.
                  By leveraging the expertise and persistence of CAP Digisoft's
                  SDRs, Frontera has successfully optimized its sales process,
                  leading to increased revenue and business growth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div class="container custom-cn-width">
          <div class="row g-5 align-items-center justify-content-center text-center">
            <div class="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div class="container custom-cn-width">
          <div class="row g-lg-5 g-4 text-center">
            <div class="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div class="">
            <div class="row g-4 g-xl-5 text-center text-xl-start">
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Merger and Acquisition Firm
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/mergers-and-acquisition-firm">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Help Finding My Agent
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/helpfindingmyagent">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Medico AI – Chrono Sort App (Attesta)
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/medico-ai-chrono-sort-app-attesta-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default Frontera;
