import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/finax/finax1.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/finax/finax2.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/finax/finax3.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/finax/finax4.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/linux.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/apache.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/off-road-1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/meeting-minder.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/buk-buddy-1.png";

const Finax = () => {
  useEffect(() => {
    document.title = "Finax | CAP Digisoft ";
  }, []);
  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Finax</h2>
          <p class="text-white text-center pb-4 pt-3">
            An intelligent, generative AI -driven platform provides dynamic,
            actionable insights to help you make informed financial decisions
            with confidence
          </p>
          <div class="img text-center">
            <img
              alt="banner image"
              class="img-fluid rounded-3 pb-5"
              src={banner}
              width={1240}
            />
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-xl-3 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Finax?</p>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-md-8 col-sm-12">
              <p>
                Finax is an intelligent, generative AI-driven platform designed
                to provide dynamic and actionable insights for individuals
                seeking to make informed financial decisions with confidence. By
                leveraging advanced algorithms and machine learning, Finax
                tailors recommendations to users' unique financial situations,
                ultimately fostering financial literacy and empowerment.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                Many individuals grapple with the complexities of personal
                finance, facing challenges such as investment uncertainty,
                budgeting dilemmas, and the overwhelming task of staying
                informed about the ever-changing financial landscape. The lack
                of personalized guidance often results in suboptimal financial
                decisions, hindering individuals from achieving their financial
                goals.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                Finax creates personalized financial profiles by analyzing
                users' income, expenses, and financial goals, providing a
                holistic view of their financial landscape. It utilizes AI
                algorithms to perform dynamic scenario analysis, helping users
                visualize the potential outcomes of different financial
                decisions and investment strategies, adapts to users' financial
                behaviors and market trends, continuously learning to provide
                up-to-date and relevant insights for more informed
                decision-making. Finax transforms complex financial data into
                clear, actionable recommendations, empowering users to implement
                changes and optimize their financial strategies.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li>Improved Financial Literacy</li>
                <li>Optimized Investments</li>
                <li>Reduced Decision Anxiety </li>
                <li>Goal Achievement</li>
                <li>Risk Mitigation</li>
                <li>Increased Financial Confidence</li>
                <li>Community Insights and Benchmarking</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                Finax marks a pivotal shift in the landscape of personal
                finance, where AI-driven insights become the cornerstone of
                informed decision-making. By combining intelligence,
                adaptability, and a user-centric approach, Finax empowers
                individuals to take control of their financial destinies,
                fostering a future where financial decisions are not just
                informed but also aligned with personal goals and aspirations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/off_road_treasure_quest">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Off Road Treasure Quest</h4>
                  </div>
                  <p class="pt-2">
                    Adventure and discovery through off-road challenges
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/meeting-minder">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Meeting Minder</h4>
                  </div>
                  <p class=" pt-2"> Meeting Scheduler & Manager App</p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/buk_buddy">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4>Buk Buddy</h4>
                  </div>
                  <p class="pt-2">Digital learning and performance insights</p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Finax;
