import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/steppa/hero.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/steppa/steppa2.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/steppa/steppa3.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/steppa/steppa4.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/xampp.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/smart-swatcher1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/fura.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/predixa1.png";

const Steppa = () => {
  useEffect(() => {
    document.title = "Steppa | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-practica-page">
      <HeaderLight />
      <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Steppa</h2>
          <p class="text-white text-center pb-4 pt-3">
            Empowering Confident Real-Time Decisions through AI
          </p>
          <div class="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-xl-3 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Steppa?</p>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-md-8 col-sm-12">
              <p>
                Steppa is an advanced AI platform that integrates seamlessly
                into existing business processes to deliver real-time insights
                and recommendations. It leverages machine learning algorithms,
                predictive analytics, and natural language processing to
                transform data into actionable intelligence, ultimately aiding
                organizations in achieving higher efficiency and
                competitiveness.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                Many businesses face the challenge of dealing with massive
                amounts of data generated in real-time. Traditional
                decision-making processes struggle to keep up with the speed and
                complexity of this data influx, leading to delayed or suboptimal
                decisions. The need for a solution that can analyze, interpret,
                and present insights in real time has never been more critical.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                Steppa seamlessly integrates diverse data sources in real time.
                Applies machine learning algorithms and predictive analytics for
                insightful decision support. Enables user-friendly interactions
                through natural language processing. Delivers instant,
                data-driven insights, fostering operational efficiency and
                competitive advantage.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img
                  class="rounded-3 py-0 img-fluid shadow-md"
                  src={cutout2}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="impact pt-2 list-styled">
                <li>User Adoption</li>
                <li>Cost Reduction</li>
                <li>Competitive Advantage</li>
                <li>Risk Mitigation</li>
                <li>Operational Efficiency</li>
                <li>Employee Empowerment</li>
                <li>Adaptability to Market Dynamics</li>
                <li>Strategic Decision Alignment</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-lg-5 pt-2">
              <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled">
                <li>XAMPP Stack</li>
                <li>Front End: Html, CSS, Javascript and Jquery </li>
                <li>Server Side Scripting: PHP </li>
                <li>Database: MySQL</li>
                <li>Data Communication: JSON</li>
                <li>Responsive Design: Bootstrap</li>
              </ul>
            </div>
            <div class="col-lg-6 col-sm-12 pt-5">
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                In conclusion, Steppa represents a paradigm shift in how
                organizations approach decision-making in the age of big data
                and rapid technological advancement. By combining the power of
                AI, machine learning, and predictive analytics, Steppa empowers
                businesses to navigate the complexities of the modern landscape
                with confidence, agility, and a competitive edge. As
                organizations continue to rely on data-driven insights, Steppa
                emerges as a pivotal tool in the journey towards making timely,
                informed decisions that drive success and growth.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/smart-swatchers">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Smart Swatcher</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    Empowering Hair Color Enthusiasts with a Mobile App
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/fura">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Fura</h4>
                  </div>

                  <p class=" pt-2">
                    AI powered speciality pharmacy prescription fulfillment
                    booster
                  </p>

                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/predixa">
                <div class="case rounded-3 h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Predixa</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    GenAI-powered forecasting solution platform
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Steppa;
