import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/tracky/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/tracky/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/tracky/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/tracky/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/js.png";
import tech8 from "../../../../src/app/assets/images/casestudy/tech/xampp.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/schooler.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/sunbelt.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/reeler.png";

const Tracky = () => {
  useEffect(() => {
    document.title = "Tracky | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-attesta-page">
      <HeaderLight />
      <div class="container-fluid pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Tracky</h2>
          <p class="text-white text-center pb-4 pt-3">
            Amazing AI-based document discovery to Reduce your review time by
            70%
          </p>
          <div class="row justify-content-center">
            <div class="img text-center">
              <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
            </div>
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Tracky?</p>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-md-8 col-sm-12">
              <p>
                Tracky is an employer and employee friendly workforce time sheet
                management tool to improve work productivity and progress in
                real-time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container custom-cn-width">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                One of our client in USA was using Spreadsheet based Timesheet
                System for tracking the time spent by Employees on their daily
                activities. The insights and progress of the project based
                activities was untraceable as the logical part of the report
                generation cannot be customized.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                We developed a system named Tracky that allows employers to
                efficiently monitor and track the time spent by employees on
                various activities and effectively generate reports based on the
                Program and /or the Funding source, Insights of the deadline,
                Projection stage. For employees, it is a simple workspace and
                time sheet management system based on tasks which can be
                validated at any stage of the project.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled">
                <li>The user can navigate to multiple projects.</li>
                <li>Employers can track Work Efficiency </li>
                <li>Manage Attendance </li>
                <li>Reporting Mangers Can Approve/ Deny Leaves</li>
                <li>User can access it anytime, anywhere </li>
                <li>
                  Generates Salary Breakup Reports to be used by companies for
                  Outsourced resources
                </li>
                <li>Generates reports based on different criteria </li>
                <li>Availability of Paystubs – anytime </li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>

            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech8} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                In the end, the client saw the real benefits of our AI system
                that understands the In the end, the client saw the real
                benefits of Tracky which has the capability of generating
                reports with insights that can be exported to the Accounting
                Software used by the client, that can save more than 3000 man
                hours every year.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/skoolers">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Skoolers</h4>
                  </div>
                  <p class=" pt-2"> Skooler supports educational planning </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/sunbelt">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Sunbelt</h4>
                  </div>
                  <p class=" pt-2">Performance Tracker & Store Optimizer</p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/reeler">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Reeler</h4>
                  </div>
                  <p class=" pt-2">The best place to meet-up</p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Tracky;
