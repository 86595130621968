import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/narrative-mrs/banner.png";

import case1 from "../../../../src/app/assets/images/casestudy/narrative-mrs/case1.jpg";
import case2 from "../../../../src/app/assets/images/casestudy/narrative-mrs/case2.jpg";

const NarrativeGen = () => {
  useEffect(() => {
    document.title = "Narrative Medical Record Summaries | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-logistics-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div class="container custom-cn-width position-relative">
          <div class="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div class="col-lg-8">
              <h5 class="text-white">Case Study: Mid-Size Law Firms</h5>
              <h1 class="text-white fw-semibold lh-sm">
                Narrative Medical Record Summaries
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center g-lg-5 g-4 align-items-center">
            <div class="col-lg-6">
              <h1 class="fw-light mb-lg-4 mb-3">Business Problem</h1>
              <p class="mt-3">
                A growing mid-sized law firm was losing control of critical
                medical information and documentation following the record
                retrieval process. Internal staff was swamped and falling
                further behind, attorneys were uneasy that they were fully
                knowledgeable about their cases and overhead costs continued to
                increase.
              </p>
            </div>
            <div class="col-lg-6">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding pt-0">
        <div class="container custom-cn-width">
          <div class="row g-lg-0 g-4 justify-content-center">
            <div class="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light text-white mb-3">Our Solution</h1>

                <ul class="mb-3 text-white text-start ps-lg-4 ps-2 list-styled">
                  <li className="text-white">
                    Attesta customized solution in the form of a Narrative
                    Summary enriched with hyperlinks to all relevant source
                    documentation.
                  </li>
                  <li className="text-white">
                    Chronological presentation of the summary and medical
                    records for effortless cross-referencing.
                  </li>
                  <li className="text-white">
                    A standardized format tailored to the firm’s unique
                    requirements while retaining flexibility for special
                    instructions and expedited processing.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light  mb-3">Customer Value</h1>
                <b className="text-dark">
                  Medical Legal Spider not only streamlined the firm's document
                  processing but also significantly:
                </b>
                <ul class="mb-3 text-start ps-lg-4 ps-2 list-styled mt-2">
                  <li className="">
                    Reduced processing costs without compromising on accuracy.
                  </li>
                  <li className="">
                    Consistently met diverse case needs, surpassing expectations
                    in both quality and punctuality.
                  </li>
                  <li className="">
                    Transformed a previously burdensome overhead into a
                    predictable, case-billable charge.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div class="container custom-cn-width">
          <div class="row g-5 align-items-center justify-content-center text-center">
            <div class="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div class="container custom-cn-width">
          <div class="row g-lg-5 g-4">
            <div class="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Related Case Studies</h1>
            </div>
          </div>
          <div class="">
            <div class="row g-4 g-xl-5 justify-content-center">
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class=" mb-0">CASE STUDY</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <div>
                      <img class="img-fluid " alt="" src={case1} />
                      <h6 class="mt-3">Leading Hospital</h6>
                      <h4 class="transition mt-0 mb-2 mb-lg-3">
                        AI-Driven Optimization of Prescription Fulfillment
                      </h4>
                    </div>
                    <Link
                      class="d-inline-block underline-border"
                      to="/ai-driven-optimization-of-prescription-fulfillment">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="mb-0">CASE STUDY</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <div>
                      <img class="img-fluid " alt="" src={case2} />
                      <h6 class="mt-3">Jugl</h6>
                      <h4 class="transition mt-0 mb-2 mb-lg-3">
                        Workforce communication made simple
                      </h4>
                    </div>
                    <Link class="d-inline-block underline-border" to="/jugl">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterOne />
    </div>
  );
};

export default NarrativeGen;
