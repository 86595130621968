import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/fura/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/fura/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/fura/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/fura/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/html.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/css.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/bootstrap.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech6 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech7 from "../../../../src/app/assets/images/casestudy/tech/js.png";
import tech8 from "../../../../src/app/assets/images/casestudy/tech/xampp.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/aiya1.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/carfidante1.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/finax1.png";

const Fura = () => {
  useEffect(() => {
    document.title = "Fura | CAP Digisoft ";
  }, []);
  return (
    <div className="casestudy-fura-page">
      <HeaderLight />
      <div class="container-fluid pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">Fura</h2>
          <p class="text-white text-center pb-4 pt-3">
            AI powered speciality pharmacy prescription fulfillment booster
          </p>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-sm-12">
              <div class="img text-center">
                <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-xl-3 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is Fura?</p>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-md-8 col-sm-12">
              <p>
                Fura is an innovative AI-powered specialty pharmacy prescription
                tool that stands at the forefront of this revolution, redefining
                the way healthcare providers manage and dispense specialty
                medications. In the ever-evolving landscape of healthcare, the
                integration of artificial intelligence has ushered in
                transformative changes.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container custom-cn-width">
          <div class="row justify-content-center">
            <div class="col-lg-9 col-sm-10">
              <div class="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                In this scenario, Fura, the AI-powered specialty pharmacy
                prescription tool, plays a crucial role in improving the access
                and delivery of specialty medications for pediatric patients
                facing unique healthcare challenges.
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                Fura employs predictive analytics to foresee adherence
                challenges and enable proactive intervention. Precision
                prescribing tailors specialty medications based on individual
                patient data for optimal treatment outcomes. Real-time drug
                interaction monitoring ensures safe co-administration and
                prevents adverse effects. Automated prior authorization
                expedites processes, reducing administrative burden and ensuring
                timely medication access.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="mb-0 pt-2 list-styled">
                <li>Enhanced Adherence Rates</li>
                <li>Personalized Treatment Plans</li>
                <li>Patient Safety and Well-being</li>
                <li>Efficient Authorization Processes</li>
                <li>Optimized Treatment Outcomes</li>
                <li>Operational Efficiency</li>
                <li>Data-Driven Decision-Making</li>
                <li>Reduced Healthcare System Burden</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
              <div class="impact-img4  d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech6} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech7} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech8} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                Fura, the AI-powered specialty pharmacy prescription tool,
                represents a groundbreaking advancement in the healthcare
                industry. By seamlessly integrating AI into the specialty
                pharmacy workflow, Fura is not only improving prescription
                accuracy and patient adherence but also driving efficiency and
                cost savings. As the healthcare landscape continues to evolve,
                Fura stands as a testament to the power of AI in revolutionizing
                patient care and pharmacy services.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/aiya">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Aiya</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    Generative AI powered knowledge assistant{" "}
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/carfidante">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Carfidante</h4>
                  </div>
                  <p class=" pt-2">
                    A application to redefine the experience of buying pre-owned
                    cars
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/finax">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Finax</h4>
                  </div>
                  <p class=" pt-2">
                    AI platform delivers insights for confident decisions
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default Fura;
