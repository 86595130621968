import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/iledger/banner.png";
import mini from "../../../../src/app/assets/images/casestudy/tech/mini-equ.png";
import cutout1 from "../../../../src/app/assets/images/casestudy/iledger/cutout1.png";
import cutout2 from "../../../../src/app/assets/images/casestudy/iledger/cutout2.png";
import cutout3 from "../../../../src/app/assets/images/casestudy/iledger/cutout3.png";

import tech1 from "../../../../src/app/assets/images/casestudy/tech/php.png";
import tech2 from "../../../../src/app/assets/images/casestudy/tech/json.png";
import tech3 from "../../../../src/app/assets/images/casestudy/tech/my-sql.png";
import tech4 from "../../../../src/app/assets/images/casestudy/tech/linux.png";
import tech5 from "../../../../src/app/assets/images/casestudy/tech/apache.png";

import case1 from "../../../../src/app/assets/images/casestudy/othercase/cluedin.png";
import case2 from "../../../../src/app/assets/images/casestudy/othercase/hireharmony.png";
import case3 from "../../../../src/app/assets/images/casestudy/othercase/intellicue.png";

const ILedgers = () => {
  useEffect(() => {
    document.title = "iLedgers | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-iledgers-page">
      <HeaderLight />
      <div class="container-fluid hero-banner pt-0 px-0 margin-minus">
        <div class="masthead tracky pt-lg-5">
          <h2 class="text-white text-center pt-5">iLedgers</h2>
          <p class="text-white text-center pb-4 pt-3">
            Revolutionizing truck fault management and reporting
          </p>
          <div class="img text-center">
            <img alt="" class="img-fluid rounded-3 pb-5" src={banner} />
          </div>
        </div>
      </div>
      <div class="about py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">What is iLedgers?</p>
                </div>
              </div>
            </div>
            <div class="col-xl-9 col-md-8 col-sm-12">
              <p>
                A comprehensive truck fault inspection is a proactive approach
                to fleet maintenance, contributing to the safety and efficiency
                of commercial vehicles. By systematically checking key
                components, conducting test drives, and promptly addressing
                faults, fleet managers can ensure that their trucks operate at
                peak performance while complying with safety and regulatory
                standards. Regular inspections are a fundamental part of
                effective fleet management, preventing unexpected breakdowns and
                minimizing downtime.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="picture">
        <div class="container custom-cn-width">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="picture-img text-center">
                <img
                  class="tracky-mockup img-fluid"
                  src={cutout1}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative py-5">
        <div class="container custom-cn-width">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Scenario</p>
                </div>
              </div>
              <p class="p-4">
                In the realm of fleet management, organizations often grapple
                with manual and disjointed processes for tracking and addressing
                truck faults. This leads to increased downtime, higher
                maintenance costs, and challenges in generating meaningful
                reports for decision-making..
              </p>
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">CDS Solution Approach</p>
                </div>
              </div>
              <p class="p-4">
                Introducing iledgers, our groundbreaking digitalized truck fault
                management system. Powered by advanced AI, iledgers automates
                fault inspections across diverse document formats, providing a
                comprehensive overview of truck health. From maintenance logs to
                diagnostic reports, iledgers intelligently extracts information,
                establishing correlations to generate insightful reports. This
                user-friendly platform enhances efficiency by streamlining the
                inspection process, capturing digital evidence through images,
                and facilitating seamless communication between drivers and
                maintenance teams.
              </p>
            </div>
            <div class="col-lg-6">
              <div class="tracky-middle  d-flex justify-content-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="picture1">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="picture1-img text-center">
                <img class="rounded-3 py-0 img-fluid" src={cutout3} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="impact py-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-3 col-sm-12 ">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Impact</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12  ">
              <ul class="mb-0 pt-2 ps-4 ps-lg-0 list-styled ps-4">
                <li>Digital Maintenance Records</li>
                <li>Automated Alerts</li>
                <li>Inspection Checklists</li>
                <li>Fuel Management</li>
                <li>Analytics Dashboard</li>
                <li>Reduced Downtime</li>
                <li>Cost Savings</li>
                <li>Compliance and Safety</li>
                <li>Improved Decision-Making</li>
              </ul>
            </div>
            <div class="col-lg-3 col-sm-12 pb-3 pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-start ">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Technology Stack</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-5">
              <div class="impact-img4 d-flex align-items-center">
                <div class="img2 p-2 pb-0 case">
                  <img src={tech1} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech2} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0  case">
                  <img src={tech3} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech4} class="img-fluid" alt="" />
                </div>
                <div class="img2 p-2 pb-0 case">
                  <img src={tech5} class="img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12  pt-5">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Conclusion</p>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-sm-12 pt-lg-5 pt-2">
              <p class="pt-2">
                iledgers proved to be a game-changer for Transportation Company,
                transforming their vehicle management processes and enhancing
                overall operational efficiency. The shift from manual
                record-keeping to a digital platform resulted in reduced
                downtime, cost savings, and improved compliance. iledgers
                continues to be an integral part of ABC Transportation's
                commitment to maintaining a reliable and efficient fleet.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="more-cases pb-5">
        <div class="container custom-cn-width">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="heading">
                <div class="content1 d-flex align-items-center">
                  <img src={mini} class="img-fluid" alt="" />
                  <p class="fs-3 ">Other Case Studies</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center py-4 g-4">
            <div class="col-lg-4 col-sm-12">
              <Link to="/clued-in-closet">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Clued.in Closet</h4>
                  </div>
                  <p class=" pt-2">
                    {" "}
                    Efficiently organize your closet within the app.{" "}
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case1} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12 ">
              <Link to="/hireharmony">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Hire Harmony</h4>
                  </div>
                  <p class=" pt-2">
                    Hire Harmony is a web application based on hiring process of
                    an organization.
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case2} alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div class="col-lg-4 col-sm-12">
              <Link to="/intellicue">
                <div class="case rounded-3  h-100 case1 p-4 bg-light">
                  <div class="case-title">
                    <h4 class="">Intellicue</h4>
                  </div>
                  <p class=" pt-2">
                    Online learning platform with mini interactive games based
                    on the topics
                  </p>
                  <div class="case-img">
                    <img class="img-fluid rounded py-3" src={case3} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </div>
  );
};

export default ILedgers;
