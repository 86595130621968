import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import HeaderLight from "../../../common/header/HeaderLight";
import FooterOne from "../../../common/footer/FooterOne";

import banner from "../../../../src/app/assets/images/casestudy/medico-vds/banner.jpg";

import icon1 from "../../../../src/app/assets/images/casestudy/common/icon1.png";
import icon2 from "../../../../src/app/assets/images/casestudy/common/icon2.png";

import PDF from "../../../app/assets/casestudypdf/medico-view-desk.pdf";

const MedicoView = () => {
  useEffect(() => {
    document.title = "Medico View desk system | CAP Digisoft ";
  }, []);

  return (
    <div className="casestudy-logistics-page">
      <HeaderLight />
      <section class="d-flex align-items-center justify-content-center banner-sec bg-dark case-study-sec inner margin-minus">
        <div class="container custom-cn-width position-relative">
          <div class="row position-relative g-3 mt-lg-4 align-items-center mb-md-2">
            <div class="col-lg-9">
              <h5 class="text-white">Case Study</h5>
              <h1 class="text-white fw-semibold">Medico – View Desk</h1>
            </div>
            <div class="col-lg-3 text-lg-end">
              <a href={PDF} class="default-btn orange-btn" target="_blank">
                <i class="fa fa-file-pdf me-1"></i>
                Download PDF
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
          <div class="row justify-content-center g-lg-5 g-4 align-items-center">
            <div class="col-lg-7 col-md-9">
              <h1 class="fw-light mb-lg-4 mb-3">Scenario</h1>
              <p class="mt-3">
                A client that owns a clinical lab approached us to discuss the
                possibility of storing their online lab records as they were
                facing challenges storing and viewing documents. They had no
                option of maintaining the history of edits made to a patient's
                record and lacked the ability to perform filter-based searches.
              </p>
            </div>
            <div class="col-lg-5 col-md-9">
              <img src={banner} class="img-fluid b-r-6" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 text-center text-lg-start section-padding pt-0">
        <div class="container custom-cn-width">
          <div class="row g-xl-5 g-4">
            <div class="col-lg-7 mb-4 mb-lg-5">
              <h1 class="fw-light">CDS Solution Approach</h1>
            </div>
          </div>
          <div class="row g-xl-5 g-4">
            <div class="col-md-6 col-lg-6">
              <div class="features-item h-100 justify-content-start">
                <div class="icons sec-primary-ic mb-4 mx-auto mx-lg-0  d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon1}
                    class="img-fluid"
                    alt="Prospect Database Cleansing"
                  />
                </div>
                <h4>Approach</h4>
                <p class="mt-3">
                  Our approach was to make it easy for clinics to register
                  within the system and log in to their accessible folder to
                  view their reports. We developed Medico - View Desk, a system
                  that creates executives for each person that would process and
                  upload Medical Reports. It is a dedicated system for Patients
                  and labs who intend to store records online.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="features-item h-100 justify-content-start">
                <div class="icons mb-4 mx-auto mx-lg-0 sec-primary-ic d-flex align-items-center justify-content-center rounded-pill">
                  <img
                    src={icon2}
                    class="img-fluid"
                    alt="Prospect Database Governance"
                  />
                </div>
                <h4>Strategy</h4>
                <p class="mt-3">
                  The previously processed documents are separated from the ones
                  that are currently being processed by the software, and all
                  documents are synchronized once they are all fully processed.
                  Each user is given the right to log in to their folder to view
                  their medical reports. An expiration time is set for each
                  folder, and the reports are viewable only until the assigned
                  time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class=" text-center text-lg-start section-padding">
        <div class="container custom-cn-width">
          <div class="row g-lg-0 g-4 justify-content-center">
            <div class="col-lg-6 col-md-9 bg-dark b-r-t-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light text-white mb-3">Results</h1>
                <p class="text-white mb-2">
                  {" "}
                  The benefits to the client included:
                </p>
                <ul class="mb-3 text-white text-start ps-lg-4 ps-2 list-styled">
                  <li className="text-white">
                    A Single pane of glass to view their records
                  </li>
                  <li className="text-white">
                    Scroll and zoom features added to the timeline
                  </li>
                  <li className="text-white">
                    Scroll and zoom features added to the timeline
                  </li>
                  <li className="text-white">
                    Easy upload and download of data
                  </li>
                  <li className="text-white">
                    History of all edits to patient records maintained
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-9 bg-warning b-r-r-b">
              <div class="p-lg-5 p-4">
                <h1 class="fw-light mb-3">Conclusion</h1>

                <p class="mt-3">
                  The system enabled the client to review all levels and aspects
                  of a patient’s medical records and summary using a unique
                  timeline system. It gave them the options to edit data and
                  track all data changes within the timeline system.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0">
        <div class="container custom-cn-width">
          <div class="row g-5 align-items-center justify-content-center text-center">
            <div class="col-lg-7">
              <h1 class="fw-light mb-4">Interested in working with us?</h1>
              <p class="mb-3">
                CAP Digisoft would be more than happy to support and help you
                derive information and knowledge with our AI engine. Our experts
                combine experience with technology to make it easy for you to
                convert your raw documents into informational records.
              </p>
              <p>
                Send us an email at{" "}
                <a className="text-primary" href="mailto:info@capdigisoft.com">
                  info@capdigisoft.com{" "}
                </a>{" "}
                and we’ll be happy to help you.
              </p>
              <a
                href="javascript:;"
                class="default-btn mt-lg-4 mt-3"
                data-bs-toggle="modal"
                data-bs-target="#meeting">
                Let's Talk
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="pb-0 section-padding">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-12">
              <hr class="my-0" style={{ opacity: ".1" }} />
            </div>
          </div>
        </div>
      </section>
      <section class="other-sec section-padding">
        <div class="container custom-cn-width">
          <div class="row g-lg-5 g-4 text-center">
            <div class="col-lg-12 mb-lg-5 mb-4">
              <h1 class="fw-light">Other Case Studies</h1>
            </div>
          </div>
          <div class="">
            <div class="row g-4 g-xl-5 text-center text-xl-start">
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">
                      Medico – Risk Chart App
                    </h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/medico-risk-chart-app-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">E-Sort Desk</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/e-sort-desk-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
              <div class="col-lg-4 col-md-6">
                <fieldset class="transition">
                  <legend class="text-xl-start">Case Studies</legend>
                  <div class="d-flex flex-column justify-content-between h-100">
                    <h4 class="transition mb-2 mb-lg-3">Liti-code System</h4>
                    <Link
                      class="d-inline-block underline-border"
                      to="/liti-code-system">
                      <span class="border-line d-inline-block me-1">
                        Learn more
                      </span>
                      <i class="fa fa-arrow-right fa-sm position-relative"></i>
                    </Link>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </div>
  );
};

export default MedicoView;
